$spacing-base-unit: 8;

.decoration-banner {
  background-color: $color-primary-teal;
  display: none;

  @include mq($breakpoint-desktop) {
    min-height: 300px;
    display: block;
    width: 100%;
    z-index: -1;
  }
}
.horizontal-padding {
  padding-left: 16px;
  padding-right: 16px;
  @include mq($breakpoint-tabletLandscape) {
    padding-left: 32px;
    padding-right: 32px;
  }
  @include mq($breakpoint-desktopMedium) {
    padding-left: 44px;
    padding-right: 44px;
  }
}

.horizontal-padding--section {
  padding-left: 16px;
  padding-right: 16px;
  @include mq($breakpoint-tablet) {
    padding-left: 32px;
    padding-right: 32px;
  }
  @include mq($breakpoint-desktopLarge) {
    padding-left: 44px;
    padding-right: 44px;
  }
}
.vertical-padding {
  padding-top: 16px;
  padding-bottom: 16px;
  @include mq($breakpoint-tabletLandscape) {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  @include mq($breakpoint-desktopMedium) {
    padding-top: 44px;
    padding-bottom: 44px;
  }
}
.vertical-padding--section {
  padding-top: 16px;
  padding-bottom: 16px;
  @include mq($breakpoint-tablet) {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  @include mq($breakpoint-desktopLarge) {
    padding-top: 44px;
    padding-bottom: 44px;
  }
}
.article-body-V2 .prose {
  line-height: 1.4 !important;
  h1 {
    font-size: 28px !important;
  }
  h2 {
    font-size: 20px !important;
    font-weight: 700 !important;
    font-family: $font-heading;

  }
  p {
    font-size: 18px !important;
    color: $color-grey-medium;
    margin-top: 10px;
  }
  @include mq($breakpoint-desktop) {
    h1 {
    font-size: 32px !important;
    }
  }
}
.standfirst-V2 {
  display: none;
  opacity: 0;
}
.article-body-V2 .prose.standfirst-V2 {
  display: block;
  opacity: 1;
  p {color: $color-primary-grey !important;
    font-size: 24px !important;
    font-weight: 500 !important;
    line-height: 1.3 !important;
    @include mq($breakpoint-desktop) {
      font-size: 26px !important;
    }
  }

}
.article-body-V2 .inverse-prose.standfirst-V2 {
  h2 {
    color: white !important;
  }
  p {
    color: white !important;
  }
}
.article-body-V2 .inverse-prose {
  p {
    color: white;
  }
}
.layout-V2 {
  .poll {
    padding: 16px;
    @include mq($breakpoint-tabletLandscape) {
    padding: 32px;
  }
  @include mq($breakpoint-desktopMedium) {
    padding-left: 44px;
  }}
}

.borderRadius--V2 {
  .prose {
    line-height: 1.4 !important;
    h1 {
      font-size: 24px !important;
    }
    h2 {
      font-size: 25px !important;
      font-family: $font-heading;
    }
    p {
      font-size: 18px !important;
      color: $color-grey-medium;
      margin-top: 10px !important;
    }
    ul {
      margin-top: 10px !important;
    }
    li {
      font-size: 18px !important;
      color: $color-grey-medium;
      margin-bottom: 10px !important;
    }
  }
  
 .inverse-prose {
   p,
   li {
    color: white !important;
   }
 }


  @include mq($breakpoint-desktop) {
.prose { 
  h1 {
    font-size: 36px !important;
    }
    h2 {
    font-size: 28px !important;
   
  }
    }
    
  }

}
.surround:has(.article-body-V2),
.surround:has(.borderRadius--V2) {
  border-radius: 9px;
}

.surround:has(.video-widget--V2),
.surround:has(.inlineImage-widget--V2) {
  border-radius: 9px;
  overflow: hidden;
}

.article__published-date {
  font-family: $font-heading;
  color: black;
  font-weight: 900;
  padding-bottom:32px;
  font-size: 18px;
    @include mq($breakpoint-desktop) {
      padding-bottom: 44px;
    }
}
.article__published-date--dark {
  color: white;
}
.faq-question {
  font-family: $font-body;
}