.button--teal {
  background-color: $color-primary-teal;
  padding: 16px;
  color: white;
  border-radius: 9px;
  font-family: $font-body;
  font-weight: 500;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
  &:hover {
    box-shadow: 0 8px 16px 0 rgba($color-primary-black, 0.4);
  }
}
