.heading1 {
  color: $color-common-black;
  font-size: 28px;
  font-weight: 900;
  font-family: $font-heading;
  @include mq($breakpoint-desktop) {
    font-size: 36px;
  }
}

.heading2 {
  font-size: 30px;
  font-weight: 900;
  font-family: $font-heading;
}
.body {
  font-size: 18px;
  font-weight: 500;
  font-family: $font-body;
  color: $color-grey-medium;
}
.article-widget-heading {
  font-size: 28px;
  font-family: $font-heading;
  font-weight: 800;
   @include mq($breakpoint-desktop) {
    font-size: 32px;
  }
}

.bbfc-rating-page-layout--body {
  h2 {
    color: $color-grey-dark;
    font-family: $font-body;
    font-size: 24px;
    line-height: 1.33;
    font-weight: 400;
    padding: 42px 0 0;
  }
  p,
  ul,
  li {
    font-size: 18px;
    font-weight: 500;
    font-family: $font-body;
    color: $color-grey-medium;
    line-height: 1.3;
  }
  .rating-standfirst {
    color: $color-primary-grey;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.3;
    @include mq($breakpoint-desktop) {
    font-size: 26px;
  }
  }
  .body-description {
    margin-top: 10px;
    @include mq($breakpoint-desktop) {
      margin-top: 20px;
    }
  }
  .body-description-h3 {
    color: $color-common-black;
    font-family: $font-heading;
    font-size: 24px;
    font-weight: 900;
    line-height: 1.41;
  }
  img {
    width: 100%;
    height: 100%;
  }
}

.rating-standfirst {
    color: $color-primary-grey;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.3;
    @include mq($breakpoint-desktop) {
    font-size: 26px;
  }
  }